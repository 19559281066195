export const generateAIImage = async (prompt, options = {}) => {

    // generation_options = {
    //     "prompt": prompt,
    //     "go_fast": request_json.get('go_fast', True),
    //     "megapixels": request_json.get('megapixels', "1"),
    //     "num_outputs": request_json.get('num_outputs', 1),
    //     "aspect_ratio": request_json.get('aspect_ratio', "1:1"),
    //     "output_format": request_json.get('output_format', "webp"),
    //     "output_quality": request_json.get('output_quality', 80),
    //     "num_inference_steps": request_json.get('num_inference_steps', 4)
    // }
    
try {
    // Cloud Function URL - replace with your actual deployed function URL
    const apiUrl = process.env.VUE_APP_GAME_IMAGE_API_URL || 
      'https://us-west2-rpg-framework-a73be.cloudfunctions.net/generate_image';
    
    // Default prompt if not provided
    const imagePrompt = prompt || 
      "Create a vivid, high-quality board game header image based on this description: " +
      "Giant with two sheep under his arms running away from angry villagers. " +
      "The image should be colorful, engaging, and representative of a board game theme.";
    
    // Default options
    const defaultOptions = {
      go_fast: true,
      output_format: "png"
    };
    
    // Merge default options with provided options
    const requestOptions = {
      prompt: imagePrompt,
      ...defaultOptions,
      ...options
    };
    
    console.log("Generating game image with prompt:", imagePrompt);
    
    // Call the Cloud Function
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestOptions)
    });
    console.log(response)
    
    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`API error: ${response.status} - ${errorText}`);
    }
    
    const result = await response.json();
    
    // Check if image URL is present
    if (result.image_url) {
      console.log('Image URL received:', result.image_url);
      return [result.image_url];
    } else {
      console.error('No image URL found in the response:', result);
      throw new Error('Image generation failed: No image URL returned');
    }
  } catch (error) {
    console.error("Error generating game image:", error);
    
    // Return a default placeholder image
    return [
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACklEQVR4nGMAAQAABQABDQottAAAAABJRU5ErkJggg=='
    ];
  }
};