<template>
  <div class="about container">
     <img style="z-index: 0; left:0; width: 100%; position: fixed;" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/CastleIntro.jpg">
     <div class="row justify-content-md-center " style='margin-bottom: 8px;'>
     <nav class="navbar-fixed-top navbar fixed-top navbar-expand-lg navbar-top justify-content-center" >
      <router-link class="navbar-brand" to="/main" style="font-size: 14px;">
    <!-- <img src="/docs/4.0/assets/brand/bootstrap-solid.svg" width="30" height="30" class="d-inline-block align-top" alt=""> -->
    <div class='pocket-brand-wrap ' style="width:20px; height:20px; float:left; border-radius: 3px; margin-right:4px; ">
      <div style=" padding:1px;"><i class="ra ra-perspective-dice-random" style="font-size: 14px;"></i></div></div>
    Pocket RPG
  </router-link>
    </nav>
      </div>

    <div class="row justify-content-md-center" style="margin-top:60px; z-index: 1;" >
      <div class="col-xs-12 ">
    <h1 class="outside-white" style="position: relative;">Pocket RPG</h1>
  </div>
</div>

    <div class="row justify-content-md-center" >
      <!-- Welcome Section - Left -->
      <div class="col-md-4">
        <div class="card start-card" >
          <div class="card-body" style="background: rgba(2,2,1,.6); color: white;">
            <h5 class="card-title">Pocket RPG: Welcome</h5>
            <p class="card-text" style="text-align:left;"> <b>Pocket RPG</b> is a collaborative role playing and story telling app. You can create characters and share them with friends. </p>
            <div v-if="changeName=='np'" class="input-group mb-3">
              {{currentUser.displayName}}
              <div class="input-group-prepend">
                <!-- <span class="input-group-text" id="basic-addon1">@</span> -->
              </div>
              <label class="label-helper" for="name">User Name</label>
              <input id="name" type="text" class="form-control" v-on:keyup="updateUsername" v-model.trim="UserForm.name" placeholder="Username " aria-label="Character" aria-describedby="basic-addon1">
            </div>
            <div>
              <!-- <router-link v-if="currentUser.displayName !=''" to="/create_character?adventure=38O97s5g3K6DlhvPD8nP" class="btn btn-success btn-sm">Adventure of the Week</router-link>  -->
              <!-- <a v-if="currentUser.displayName !=''" style="margin-left:10px" href="" v-on:click.prevent="toggleCharacters()" class="btn btn-secondary btn-sm">Toggle Characters and DM</a> -->
            </div>
          </div>
        </div>
      </div>

      <!-- Adventure of the Week - Middle -->
      <div class="col-md-4">
        <div class="card adventure-card">
          <img class="card-img-top" :src="adventureOfWeek.image_src || 'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Shattered_Start.jpg'" alt="Adventure of the Week" style="height:100%; object-fit: cover;">
          <div class="card-body" style="background: rgba(2,2,1,.6); color: white;">
            <h5 class="card-title">Adventure of the Week</h5>
            <p class="card-subtitle">Join this week's featured quest - a unique adventure awaits!</p>
            <div class="quest-summary">
              <p class="font-weight-normal" style="font-size: 14px;">{{ adventureOfWeek.description || 'Join this week\'s featured adventure!' }}</p>
            </div>
            <router-link v-if="currentUser.displayName !=''" :to="'/adventure/' + adventureOfWeek.id" class="btn btn-success btn-sm">Start Adventure</router-link>
          </div>
        </div>
      </div>

      <!-- Invites Section - Right -->
      <div class="col-md-4">
        <div class="card character-card">
          <img class="card-img-top" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Shattered_Start.jpg" alt="Invites background" style=" object-fit: cover;     width: 100%;">
          <div class="card-body" style="background: rgba(2,2,1,.6); color: white;">
            
            <h5 class="card-title">Invites</h5>
            <p class="card-subtitle">Join fellow adventurers on their epic quests!</p>
            <div class="quest-summary" ><p class="font-weight-normal" style="font-size: 14px;">You have been invited to Adventure!</p></div>
            <button class="btn btn-primary btn-sm float-right" @click="showInvites = !showInvites">{{ showInvites ? 'Hide' : 'Show' }} Invites</button>
            <div v-if="showInvites">
              <ListInvites :type="'adventure'" :showType="'user'" :query="this.$route.params.id"></ListInvites>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Navigation Bar -->
   
    <div class="row justify-content-md-center" style="margin-bottom: 30px;     z-index: 1;
    position: relative;">
      <div class="col-md-8">
        <div class="section-nav">
          <button 
            :class="['nav-btn', { active: activeSection === 'events' }]" 
            @click="activeSection = 'events'">
            Recent Events
          </button>
          <button 
            :class="['nav-btn', { active: activeSection === 'heroes' }]" 
            @click="activeSection = 'heroes'">
            Heroes
          </button>
          <button 
            :class="['nav-btn', { active: activeSection === 'adventures' }]" 
            @click="activeSection = 'adventures'">
            Adventures
          </button>
        </div>
      </div>
    </div>

    <!-- Heroes Section -->
    <div v-show="activeSection === 'heroes'">
       <div class="row justify-content-md-center" style="margin-bottom: 30px;     z-index: 1;
    position: relative;">
      <div class="col-md-8">
        <router-link v-if="currentUser.displayName !=''" to="/create_character" class="btn btn-primary btn-sm">New Character</router-link>
      <router-link v-if="currentUser.displayName !=''" to="/create_character?playtest=combat" class="btn btn-danger btn-sm">Playtest a Combat Scene!</router-link> 
        <div class="section-nav">
        </div>
      </div>
    </div>
      <div class="row justify-content-md-center">
        <div class="col-xs-12 col-md-6">
          <h4 v-if="!stateDM" style="color:white; position: relative;">{{currentUser.displayName}}'s Heroes</h4>
          <h4 v-else style="color:white; position: relative;">Following other Heroes & DM</h4>
        </div>
      </div>
      <div class="row justify-content-left">
        <div v-for="character in character_list" v-bind:key='character' class="col-xs-4 col-md-4">
          <div class="card character-card">
            <img class="card-img-top" src="/" alt="Card image cap">
            <img :src="characterCurrentSceneImage(character)" alt="Card image cap" class="card-img-top" style="height:100%;">
            <div class="card-body" style="background: rgba(2,2,1,.6); color: white;">
              <h5 class="card-title shimmer" style='margin-top:20px; text-align: left;'>
                <router-link v-if="!stateDM" :to="'character/'+character.id" style="color:white; text-decoration:none;">
                  <div class="small-image" style="float:left;">
                    <img v-if="character.src" class="" :src="character.src" :style="characterImage(character.src)">
                    <img v-else class="small-image" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/in-the-style-of-a-black-and-white-pencil-drawing-a-partially-completed-sketch-of-an-archer-with-a-t.png">
                  </div>
                  <div style="float:left;">{{ character.name }}</div>
                </router-link>
                <router-link v-else :to="'character/'+character.id+'?gm=true'" style="color:white; text-decoration:none;">
                  <div class="small-image" style="float:left;">
                    <img v-if="character.src" class="" :src="character.src" :style="characterImage(character.src)">
                    <img v-else class="small-image" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/in-the-style-of-a-black-and-white-pencil-drawing-a-partially-completed-sketch-of-an-archer-with-a-t.png">
                  </div>
                  <div style="float:left;">{{ character.name }}</div>
                </router-link>
              </h5>
              <div class="quest-summary"><p class="font-weight-normal" style="font-size: 14px;">{{ character.quest }}</p></div>
              <p>
                <router-link v-if="!stateDM" :to="'character/'+character.id" style="color:white; text-decoration:none;">
                  <span class="oi oi-comment-square space-icon"></span> Continue The Tale
                </router-link>
                <router-link v-else :to="'character/'+character.id+'?gm=true'" style="color:white; text-decoration:none;">
                  <span class="oi oi-comment-square space-icon"></span> Read The Tale
                </router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Adventures Section -->
    <div v-show="activeSection === 'adventures'">
      <h2 class="outside-white" style="position: relative;">
        Adventures Created 
        <router-link v-if="currentUser.displayName !=''" to="/create_adventure" class="btn btn-success btn-sm">Create Adventure</router-link>
      </h2>
      <div class="row justify-content-left">
        <div v-for="adventure in adventures_list" v-bind:key='adventure' class="col-xs-4 col-md-4">
          <div class="card character-card">
            <img :src="adventure.image_src" alt="Card image cap" class="card-img-top" style="height:100%;">
            <div class="card-body" style="background: rgba(2,2,1,.6); color: white;">
              {{adventure.title}} 
              <router-link :to="'/adventure/'+adventure.id" class="btn btn-primary btn-sm">Adventure</router-link>
              <div class="quest-summary"><p class="font-weight-normal" style="font-size: 14px;">{{ adventure.description }}</p></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Events Section -->
    <div v-show="activeSection === 'events'">
      <h2 class="outside-white" style="position: relative;">Recent Events</h2>
      <div class="row justify-content-md-center" style="margin-bottom: 20px;">
        <div class="col-md-8">
          <p class="outside-white" style="font-size: 1.1rem; opacity: 0.8;">Follow the latest adventures and achievements of fellow heroes across the realm</p>
        </div>
      </div>
      <div class="row justify-content-left">
        <div v-for="event in event_list" v-bind:key='event' class="col-xs-4 col-md-4">
          <div class="card event-card">
            <img class="event-image" v-if="event.background_src" style="height:240px; object-fit: cover;" :src="event.background_src">
            <img class="event-image" v-else style="height:240px; object-fit: cover;" :src="eventImage(event.tilt)">
            <div class="card-body" style="background: rgba(2, 2, 1, 0.6); color: white;">
             <!--  <div class="goal-text" style="font-size:14px; margin-top: 15px;">
                <b>Goal:</b> {{ event.goal }}
              </div> -->
               <div v-if="event.tilt" class="result-indicator">
                {{result_text(event.tilt)}} <i :class="'ra '+result_icon(event.tilt)"></i>
              </div>
              <div class="event-summary">
                <p class="font-weight-normal" style="font-size: 14px;">{{ event.content }}</p>
              </div>
              <h5 class="card-title shimmer" style="margin: 0; text-align: left;">
                <router-link :to="'/character/'+event.character_id" style="color:white; text-decoration:none; display: flex; align-items: center;">
                  <div class="small-image">
                    <img v-if="event.character_src" :src="event.character_src" :style="characterImage(event.character_src)">
                    <img v-else src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/in-the-style-of-a-black-and-white-pencil-drawing-a-partially-completed-sketch-of-an-archer-with-a-t.png">
                  </div>
                  <div>{{ event.character_name }}</div>
                </router-link>
              </h5>
           <!--    <router-link 
                :to="'/character/'+event.character_id+'?gm=true'" 
                class="read-tale-link"
                style="color:white; text-decoration:none;">
                <span class="oi oi-comment-square space-icon"></span> Read The Tale
              </router-link> -->
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
  import { mapState } from 'vuex'
  import ListInvites from "./ListInvites.vue";

  import moment from 'moment'
  const fb = require('../firebaseConfig.js')

  export default {
    data() {
      return {
        changeName: 'no',
        stateDM: false,
        showInvites: false,
        UserForm: {
          name : this.$store.state.currentUser.displayName
        },
        publicPath: process.env.BASE_URL,
        followingCharacters: [],
        character_list: [],
        adventures_list: [],
        adventureOfWeek: {},
        event_list: [],
        activeSection: 'events' // Default to events section
      }
    },
    components: {
      ListInvites
    },
    computed: {
      ...mapState(['userProfile', 'currentUser', 'characters_list'])
    },
    beforeMount(){
      this.loadCharacters()
      this.loadAdventures()
      this.loadAdventureOfWeek()
      this.loadEvents()
    },
    methods:{
      characterImage(src){
      var response = '';
      var calc = {'y':0,'x':0,'height':0,'width':0}
      var pop = src.split('?')
      pop = pop[1]
      pop = pop.split('&')
      pop.forEach(function(pair) {
        var key = pair.split('=')[0];
        var value = pair.split('=')[1];
        calc[key] = value;
      });
      response = 'position:absolute; left:-'+calc['x']+'px; top:-'+calc['y']+'px; height:'+Math.round(calc['height'])+'px; width:'+Math.round(calc['width'])+'px;';
      return response
    },
      characterCurrentSceneImage(character){
        var type = ''
        try{
          type = character.events[character.events.length -1].type;  
        }catch{
          console.log('failed')
        }
        if(type !=''){
          return this.eventImage(type)
        }else{
          return 'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/in-the-style-of-a-black-and-white-pencil-drawing-a-partially-completed-sketch-of-an-archer-with-a-t.png'
        }
      },
    //   eventImage(type){
    //   var clean_type = new String(type).toLowerCase();
    //   if(clean_type=='start'){
    //     return 'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Shattered_Start.jpg'
    //   }
    //   if(clean_type=='exploration'){
    //     return 'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Shattered_Dungeon.jpg'
    //   }
    //   if(clean_type=='creature'){
    //     return 'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Shattered_Creature.jpg'
    //   }
    //   if(clean_type=='boss' || clean_type=='nemisis'){
    //     return 'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Generate%20an%20image%20of.jpg'
    //   }
    //   if(clean_type=='combat'){
    //     return 'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Scene%20Backgrounds/combat.jpeg'
    //   }
    //   return 'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Shattered_Start.jpg'
    // },
      toggleCharacters(){
        if(this.stateDM){
          this.loadCharacters()
        }else{
          this.getFollowingCharacters()
        }
        this.stateDM = !this.stateDM

      },
      updateUsername :function(event){

        var name = this.UserForm.name;
        // console.log(this.UserForm.name);
        // console.log(this.$store.state.currentUser)
        if(event.keyCode === 13){
          console.log("enter key pressed")
          console.log(this.$store.state.currentUser.uid)
        var user = fb.auth.currentUser;
         user.updateProfile({
            displayName: name
          }).then(function() {
            // Update successful.
            console.log('successful')
          }).catch(function(error) {
            console.log('an error occured')
            console.log(error)
          });

      }
    },
    getFollowingCharacters: function(){
      var follows = this.$store.state.userProfile.characterFollows;
      fb.charactersCollection.where('__name__', 'in' ,follows).limit(6).onSnapshot(querySnapshot => {
            // check if created by currentUser
            //maybe breaking
            // var createdByCurrentUser = ''
            // var changes = querySnapshot.docChanges();
            // if (querySnapshot.docs.length) {
            //     createdByCurrentUser = this.store.state.currentUser.uid == changes[0].doc.data().userId ? true : false
            // }
                let charactersArray = []

                querySnapshot.forEach(doc => {
                    let character= doc.data()
                    character.id = doc.id
                    charactersArray.push(character)
                })

                this.character_list = charactersArray;
        })

    },
    loadAdventures: function(){
      var user = this.currentUser
      fb.adventuresCollection.where('creatorId','==',user.uid).orderBy('createdOn', 'desc').limit(6).onSnapshot(querySnapshot => {
              let adventuresArray = []
              querySnapshot.forEach(doc => {
                  let adventure= doc.data()
                  adventure.id = doc.id
                  adventuresArray.push(adventure)
              })
              console.log(adventuresArray)
              this.adventures_list = adventuresArray
      })
    },
    loadCharacters: function(){
      var user = this.currentUser
      fb.charactersCollection.where('userId','==',user.uid).orderBy('createdOn', 'desc').limit(6).onSnapshot(querySnapshot => {
              let charactersArray = []

              querySnapshot.forEach(doc => {
                  let character= doc.data()
                  character.id = doc.id
                  charactersArray.push(character)
              })
              this.character_list  = charactersArray
              // this.store.commit('setCharacters', charactersArray)
      })
    },
    loadAdventureOfWeek: function() {
      // Get the adventure with ID 38O97s5g3K6DlhvPD8nP (from the "Adventure of the Week" button)
      fb.adventuresCollection.doc('38O97s5g3K6DlhvPD8nP').get().then(doc => {
        if (doc.exists) {
          let adventure = doc.data()
          adventure.id = doc.id
          this.adventureOfWeek = adventure
        }
      })
    },
    loadEvents: function() {
      fb.activityCollection.orderBy('createdOn', 'desc').limit(6).onSnapshot(querySnapshot => {
        let eventsArray = []
        querySnapshot.forEach(doc => {
          let event = doc.data()
          event.id = doc.id
          eventsArray.push(event)
        })
        this.event_list = eventsArray
      })
    },
    eventImage: function(result) {
      var clean_type = this.result_text(result)
      if(clean_type == 'failed') {
        return 'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Scene%20Backgrounds/JokeFall.jpeg'
      }
      if(clean_type == 'partial success') {
        return 'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Scene%20Backgrounds/PartialSuccess.jpeg'
      }
      if(clean_type == 'success') {
        return 'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Scene%20Backgrounds/success.jpeg'
      }
      return 'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Shattered_Start.jpg'
    },
    result_text: function(calculated_result) {
      if(calculated_result < 6) {
        return 'failed'
      } else if (calculated_result > 8) {
        return 'success'
      } else if(calculated_result > 5) {
        return 'partial success'
      }
      return calculated_result
    },
    result_icon: function(calculated_result) {
      if(calculated_result < 6) {
        return 'ra-falling'
      } else if (calculated_result > 8) {
        return 'ra-muscle-fat'
      } else if(calculated_result > 5) {
        return 'ra-player-shot'
      }
      return calculated_result
    }
  },
    filters: {
      formatDate(val) {
        if (!val) { return '-' }
          let date = val.toDate()
        return moment(date).fromNow()
      },
      trimLength(val) {
        if (val.length < 200) { return val }
          return `${val.substring(0, 200)}...`
      }
    }
  }
</script>

<style scoped>
.card {
  height: 100%;
  margin-bottom: 20px;
}

.card-body {
  display: flex;
  flex-direction: column;
  padding-bottom: 1.25rem;
}

.card-text {
  flex-grow: 1;
}

.btn {
  margin: 2px;
}

.character-card, .adventure-card {
  margin-bottom: 30px;
}

.col-xs-4 {
  margin-bottom: 30px;
}

.card-subtitle {
  color: #ccc;
  font-style: italic;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 0.75rem;
}

.quest-summary {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  flex-grow: 1;
}

.quest-summary p {
  margin-bottom: 0;
  line-height: 1.4;
}

.event-card {
  height: 100%;
  margin-bottom: 30px;
  background: black;
}

.event-card .card-body {
  padding: 1.25rem;
  margin-top: 120px;
}

.event-card .small-image {
  width: 32px;
  height: 32px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
  position: relative;
}

.event-card .small-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.event-card hr {
  border-color: rgba(255, 255, 255, 0.2);
  margin: 0.75rem 0;
}

.event-card .result-indicator {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  margin-top: 10px;
  font-size: 0.9rem;
  background: rgba(255, 255, 255, 0.1);
}

.event-card .result-indicator i {
  margin-left: 5px;
}

.event-card .read-tale-link {
  margin-top: 15px;
  display: inline-block;
  padding: 5px 0;
}

.event-card .read-tale-link:hover {
  text-decoration: underline !important;
}

.shimmer {
  position: relative;
  overflow: hidden;
}

.section-nav {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  background: rgba(2, 2, 1, 0.6);
  border-radius: 8px;
}

.nav-btn {
  padding: 10px 20px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: transparent;
  color: white;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1rem;
}

.nav-btn:hover {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.3);
}

.nav-btn.active {
  background: rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.4);
  font-weight: bold;
}

@media (max-width: 767px) {
  .col-md-6, .col-xs-4 {
    margin-bottom: 30px;
  }
}
</style>