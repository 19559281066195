// Old
//import firebase from 'firebase'
//import 'firebase/firestore'

//import firebase from 'firebase/compat/app';
// import { getAuth } from "firebase/auth";
//import 'firebase/compat/firestore';

// import { getFirestore } from "firebase/firestore";


import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

// For Vertex AI we need to use the modular syntax
import { getApp } from 'firebase/app';
import { initializeApp } from 'firebase/app';

import { getVertexAI, getGenerativeModel } from "firebase/vertexai"

// firebase init goes here
const config = {
  apiKey: 'AIzaSyBTNzWkhjQAvmQbAkhyGcTo2rIas2DVCFE',
  authDomain: 'rpg-framework-a73be.firebaseapp.com',
  databaseURL: 'https://rpg-framework-a73be.firebaseio.com',
  projectId: 'rpg-framework-a73be',
  storageBucket: 'rpg-framework-a73be.appspot.com',
  messagingSenderId: '391043649787',
  appId: '1:391043649787:web:1af02cad46a99c54e6605e',
  measurementId: 'G-SYCG68H9BP'
}
//old
//firebase.initializeApp(config)
// import { initializeApp } from "firebase/app"

firebase.initializeApp(config);


// firebase utils
//const db = firebase.firestore()
const db = firebase.firestore();

//old
//const auth = firebase.auth()
const auth = firebase.auth();

const currentUser = auth.currentUser

// Initialize the Vertex AI service
let modularApp;
try {
  // Try to get the default app that was initialized
  modularApp = getApp();
} catch (error) {
  // If no app exists, initialize a new one
  modularApp = initializeApp(config);
}

const vertexAI = getVertexAI(modularApp);

// Create a `GenerativeModel` instance with a model that supports your use case
// Create and export the Gemini model
export const geminiModel = getGenerativeModel(vertexAI, { 
  model: "gemini-1.5-flash",
  generation_config: {
    max_output_tokens: 2048,
    temperature: 0.7,
  }
});

// date issue fix according to firebase
// old
// const settings = {
// }
// db.settings(settings)

// firebase collections
const usersCollection = db.collection('users')
const charactersCollection = db.collection('characters')
const factionsCollection = db.collection('factions')
const npcsCollection = db.collection('npcs')
const itemsCollection = db.collection('items')
const locationsCollection = db.collection('location')
const commentsCollection = db.collection('comments')
const adventuresCollection = db.collection('adventures')
const activityCollection = db.collection('activity')
const invitesCollection = db.collection('invites')
const storage = firebase.storage()

export {
  db,
  auth,
  currentUser,
  usersCollection,
  charactersCollection,
  npcsCollection,
  itemsCollection,
  locationsCollection,
  commentsCollection,
  factionsCollection,
  adventuresCollection,
  activityCollection,
  invitesCollection,
  storage
}
