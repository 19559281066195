import {createApp} from 'vue' 
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
// import 'jquery/src/jquery.js'
// import 'bootstrap/dist/js/bootstrap.min.js'
import './assets/css/app.css'
// Commenting out SCSS import to avoid sass-loader requirement
// import './assets/scss/app.scss'
import './assets/scss/rpg-awesome.min.css'
import './assets/scss/open-iconic-bootstrap.css'
// https://icons.getbootstrap.com/
import 'bootstrap-icons/font/bootstrap-icons.css';
// import '~/node_modules/rpg-awesome/css/rpg-awesome.min.css'

import router from './router'
import {store} from './store/index.js'
// import VueMoment from 'vue-moment'
import moment from 'moment'
// import VueMeta from 'vue-meta'

// app.use(VueMeta, {
//   // optional pluginOptions
//   refreshOnceOnNavigation: true
// })
// app.use(require('vue-moment'));
// app.use(router)

const fb = require('./firebaseConfig.js')

// Vue.config.productionTip = false

// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')

let app
fb.auth.onAuthStateChanged(() => {
        if (!app) {
        // console.log('user no app')
        // console.log(user)
        const app = createApp(App)
        app.use(store)
        app.use(router)
        app.mount('#app')
        app.config.globalProperties.$moment=moment
}
})