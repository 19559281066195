<template>
  <div class="modal fade" id="aiImageModal" tabindex="-1" aria-labelledby="aiImageModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="aiImageModalLabel">Generate AI Image</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="form-group mb-3">
            <label for="prompt" class="form-label">Image Description</label>
            <textarea 
              class="form-control" 
              id="prompt" 
              v-model="prompt" 
              rows="3" 
              placeholder="Describe the image you want to generate..."
            ></textarea>
          </div>
          
          <div v-if="isLoading" class="text-center">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <p class="mt-2">Generating image...</p>
          </div>

          <div v-if="error" class="alert alert-danger" role="alert">
            {{ error }}
          </div>

          <div v-if="generatedImage" class="generated-image-container">
            <img :src="generatedImage" alt="Generated image" class="img-fluid">
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button 
            type="button" 
            class="btn btn-primary" 
            @click="generateImage" 
            :disabled="isLoading || !prompt"
          >
            Generate
          </button>
          <button 
            v-if="generatedImage" 
            type="button" 
            class="btn btn-success" 
            @click="selectImage"
            data-bs-dismiss="modal"
          >
            Select Image
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { generateAIImage } from '../helpers/image_gen.js'

export default {
  name: 'AIImageModal',
  emits: ['image-selected'],
  setup(props, { emit }) {
    const prompt = ref('')
    const generatedImage = ref(null)
    const isLoading = ref(false)
    const error = ref(null)

    async function generateImage() {
      if (!prompt.value) return

      isLoading.value = true
      error.value = null
      
      try {
        const imageUrls = await generateAIImage(prompt.value, {"aspect_ratio":"16:10"})
        if (imageUrls && imageUrls.length > 0) {
          generatedImage.value = imageUrls[0]
        } else {
          throw new Error('No image generated')
        }
      } catch (err) {
        error.value = 'Failed to generate image. Please try again.'
        console.error('Error generating image:', err)
      } finally {
        isLoading.value = false
      }
    }

    function selectImage() {
      if (generatedImage.value) {
        emit('image-selected', generatedImage.value)
      }
    }

    function setPrompt(text) {
      prompt.value = text
    }

    return {
      prompt,
      generatedImage,
      isLoading,
      error,
      generateImage,
      selectImage,
      setPrompt
    }
  }
}
</script>

<style scoped>
.generated-image-container {
  margin-top: 1rem;
  text-align: center;
}

.generated-image-container img {
  max-width: 100%;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>