// import * as Vue from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
//import firebase from 'firebase'
import { getAuth } from "firebase/auth";
// import formbricks from "../helpers/formbricks.js";
// import formbricks from "../helpers/formbricks.js";
import formbricks from "@formbricks/js";


import MainHome from '../views/MainHome.vue'

// import MainLogin from '../views/MainLogin.vue'
import MainDashboard from '../views/MainDashboard.vue'

// Adventure
// CreateCharacter
import CharacterCreate from '../views/adventure/CharacterCreate.vue'
import CreateAdventure from '../views/adventure/CreateAdventure.vue'
import SingleCharacter from '../views/adventure/SingleCharacter.vue'
import ViewAdventure from '../views/adventure/ViewAdventure.vue'

// These imports were causing errors but are not actually used
// import Dashboard from '../views/Dashboard.vue'
// import Home from '../views/Home.vue'
// import Login from '../views/Login.vue'

// old delete
// Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: MainHome,
    meta:{
      title:'Pocket RPG - Home',
      og: {
        title: 'Pocket RPG - Home',
      descriotion:'',
      image:'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Generate%20fantasy%20con.jpg'
    }
    }
  },
  {
    path: '/main',
    name: 'main',
    component: MainDashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/character/:id',
    name: 'SingleCharacter',
    component: SingleCharacter,
    meta: {
      title:'Pocket RPG - Character Page',
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'MainLogin',
    //This might have broked something, the main login...
    meta: {
      title:'Pocket RPG - Login'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/MainLogin.vue')
  },{
    path:'/create_character',
    name:'CharacterCreate',
    component:CharacterCreate,
    meta: {
      requiresAuth: true
    }
  },{
    path:'/create_adventure',
    name:'CreateAdventure',
    component:CreateAdventure,
    meta: {
      requiresAuth: true
    }
    },{
    path:'/adventure/:id',
    name:'Adventure',
    component:ViewAdventure,
    meta: {
      requiresAuth: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0};
  }
})

router.afterEach(() => {
  if (typeof formbricks !== "undefined") {
    formbricks.registerRouteChange();
  }
  });


router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
    const auth = getAuth();
    const currentUser = auth.currentUser;
    document.title = to.meta.title || 'Pocket RPG'
    // was && !currentUser
    if (requiresAuth && !currentUser) {
        next('/login')
        // was && currentUser
    } else {
      // console.log('init Form check')
      // console.log(this.$store.state.userProfile)
      // console.log(formbricks)
      // typeof formbricks == "undefined" &&
      // if ( currentUser) {
      //   console.log('not init setting')
      //   console.log(currentUser.uid)
      //   formbricks.init({
      //       environmentId: "clt8z2eko27y35zwkchhbv1q7",
      //       apiHost: "https://app.formbricks.com",
      //       userId: currentUser.uid
      //     });
      // }
        next()
    }
})

export default router
